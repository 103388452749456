import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import {bbox} from 'ol/loadingstrategy';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS';
import {defaultTo} from 'ramda';
import {AccessMethod, Layer, LayerType} from '../model/layer/layer';
import {VectorLayerLoader} from './vectorLayerLoader';
import {bufferTime, filter, map, mapTo} from 'rxjs/operators';
import {fromEventPattern, merge, ReplaySubject} from 'rxjs';
import {setKartlagFeiler} from '../store/map/actions';
import {store} from '../store';

type ProxyToken = {
    tileToken: string;
};

const proxyToken: Promise<ProxyToken> = fetch('/api/mapproxytoken').then(res => res.json());

const addGktToken = (token: string) => (url: string) => {
    const hasParam = url.match(/.*\?((.*=.*)(&?))+/);
    if (hasParam) {
        return `${url}&gkt=${token}`;
    } else {
        return `${url}?gkt=${token}`;
    }
};

const addTokenToConfig = (token: string, config: Layer): Layer => ({
    ...config,
    urls: (config.urls || []).map(addGktToken(token)),
});

export function isResolvedBaseLayer(layer: BaseLayer | Promise<TileLayer<WMTS | TileWMS>>): layer is BaseLayer {
    return layer instanceof BaseLayer;
}

export function isPromiseOfLayer(layer: BaseLayer | Promise<TileLayer<WMTS | TileWMS>>): layer is Promise<TileLayer<WMTS | TileWMS>> {
    return !!(layer && (layer as Promise<TileLayer<WMTS | TileWMS>>).then);
}

export const createLayer = (layer: Layer): BaseLayer | Promise<TileLayer<WMTS | TileWMS>> => {
    switch (layer.type) {
        case LayerType.WMS:
            return createLayerWms(layer);
        case LayerType.WMTS:
            return createLayerWmts(layer);
        case LayerType.VECTOR:
            return createLayerVector(layer);
        default:
            return null;
    }
};

function createLayerVector(layer: Layer): VectorLayer<VectorSource> {
    const source = new VectorSource({
        strategy: bbox,
    });
    source.setLoader(VectorLayerLoader(source, layer));
    const vectorLayer = new VectorLayer({
        source,
        ...layer.options,
    });
    vectorLayer.set('layerName', layer.id);
    return vectorLayer;
}


function createAuthHeaders(layer: Layer) {
    if (layer.access != AccessMethod.MAPP) {
        return {};
    }
    return fetch('api/mapproxytoken')
       .then(res => res.json())
       .then(json => {
           return {
               headers: {
                   Authorization: `Bearer ${json.tileToken}`
               }
           }
       });
}

async function createLayerWmts(layer: Layer): Promise<TileLayer<WMTS>> {
    const capabilitiesParser = new WMTSCapabilities();
     const headers = await createAuthHeaders(layer);
        try {
            const text = await fetch(layer.urls[0], headers)
                .then(res => {
                    if (!res.ok) {
                        Promise.reject(res);
                    }
                    return res.text();
                });

            const capabilities = capabilitiesParser.read(text);
            const options = optionsFromCapabilities(capabilities, {
                layer: layer.params.LAYERS,
                format: layer.params.FORMAT,
                crossOrigin: 'anonymous',
                matrixSet: layer.params.TILEMATRIXSET,
                projection: layer.params.PROJECTION,
            });
            
            const source = new WMTS(options);
            
            if (layer.access === AccessMethod.MAPP) {
                source.setUrl(layer.tileUrl)
                source.setTileLoadFunction(createMapProxyTileLoader(proxyToken));
            }
            
            const tile = new TileLayer<WMTS>({
                preload: Infinity,
                source,
                visible: layer.options.visible,
                zIndex: layer.options.zIndex
            });


            tile.set('layerName', layer.id);
            return tile;

        } catch {
            throw `Could not load layer ${layer.id}`;
        }
}

function createLayerWms(layer: Layer): TileLayer<TileWMS> {
    const {
        params,
        options: { zIndex, visible, maxResolution },
        urls,
    } = layer;

    const defaultMaxRes = defaultTo(Number.MAX_SAFE_INTEGER);

    const source = new TileWMS({
        urls,
        params,
        crossOrigin: 'anonymous',
    });

    const l = new TileLayer({
        preload: Infinity,
        zIndex,
        visible,
        minResolution: 0,
        maxResolution: defaultMaxRes(maxResolution),
        source,
    });

    if (layer.access === AccessMethod.MAPP) {
        source.setTileLoadFunction(createMapProxyTileLoader(proxyToken));
    }

    l.set('layerName', layer.id);
    return l;
}

export const setTileLoadeError = function (layer: TileLayer<TileWMS | WMTS>): void {
    const source = layer.getSource();

    const error = fromEventPattern(
        handler => source.on('tileloaderror', handler),
        handler => source.un('tileloaderror', handler)
    );

    const success = fromEventPattern(
        handler => source.on('tileloadend', handler),
        handler => source.un('tileloadend', handler)
    );

    const errorSuccess = merge(error.pipe(mapTo('error')), success.pipe(mapTo('success')));

    const errorSuccessBuffered = errorSuccess.pipe(
        bufferTime(10000),
        map(val => ({
            success: val.filter(v => v === 'success').length,
            error: val.filter(v => v === 'error').length,
        })),
        filter(val => val.error !== 0),
        filter(val => !!(!val.success && val.error)),
        mapTo(layer.get('title'))
    );

    const replayError = new ReplaySubject();

    errorSuccessBuffered.subscribe(replayError);

    layer.set('layerFailed$', replayError);
    errorSuccessBuffered.subscribe((title) => {
        layer.setVisible(false);
        store.dispatch(setKartlagFeiler(title));
    });
};


export const withBaatToken = (layerArray: Layer[], token: string): Layer[] => {
    const withBaat = layerArray
        .filter(layer => layer.access === AccessMethod.BAAT)
        .map(layer => addTokenToConfig(token, layer));
    const withoutBaat = layerArray.filter(l => l.access !== AccessMethod.BAAT);
    return [...withBaat, ...withoutBaat];
};

async function loadImage(uri, token): Promise<string> {
    const blob = await fetch(uri, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token.tileToken}`,
        },
    }).then(res => res.blob());

    return new Promise(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result as string);
        });
        reader.readAsDataURL(blob);
    });
}

function createMapProxyTileLoader(tokenPromise) {
    return async (imageTile, src) => {
        const token = await tokenPromise;
        imageTile.getImage().src = await loadImage(src, token);
    };
}
