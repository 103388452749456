import { LayerType, Layer, AccessMethod, LayerGroup } from '../model/layer/layer';

export const layers: Layer[] = [
    {
        id: 'norge_graatone',
        group: LayerGroup.BG,
        type: LayerType.WMTS,
        access: AccessMethod.NONE,
        urls: ['https://cache.kartverket.no/v1/wmts?service=WMTS&request=GetCapabilities'],
        params: {
            LAYERS: 'topograatone',
            TILED: true,
            FORMAT: 'image/png',
            VERSION: '1.0.0',
            TILEMATRIXSET: 'utm33n',
            PROJECTION: 'EPSG:25833'
        },
        options: {
            attribution: 'Statens kartverk',
            zIndex: 11,
            visible: true
        },
    },
    {
        id: 'sverige_nedtonad',
        group: LayerGroup.BG,
        type: LayerType.WMTS,
        access: AccessMethod.MAPP,
        urls: ['https://app-md-mapproxy-prod.azurewebsites.net/api/proxy/sverige_wmts?request=GetCapabilities&service=wmts'],
        tileUrl: 'https://app-md-mapproxy-prod.azurewebsites.net/api/proxy/sverige_wmts?',
        params: {
            LAYERS: 'topowebb_nedtonad',
            TILED: true,
            FORMAT: 'image/png',
            PROJECTION: 'EPSG:3857'
        },
        options: {
            attribution: 'Lantmäteriet',
            zIndex: 10,
            visible: true,
        },
    },
    {
        id: 'norge',
        group: LayerGroup.BG,
        type: LayerType.WMTS,
        access: AccessMethod.NONE,
        urls: ['https://cache.kartverket.no/v1/wmts?service=WMTS&request=GetCapabilities'],
        params: {
            LAYERS: 'topo',
            TILED: true,
            FORMAT: 'image/png',
            VERSION: '1.0.0',
            TILEMATRIXSET: 'utm33n',
            PROJECTION: 'EPSG:25833'
        },
        options: {
            attribution: 'Statens kartverk',
            zIndex: 10,
            visible: false
        },
    },
    {
        id: 'sverige',
        group: LayerGroup.BG,
        type: LayerType.WMTS,
        access: AccessMethod.MAPP,
        urls: ['https://app-md-mapproxy-prod.azurewebsites.net/api/proxy/sverige_wmts?request=GetCapabilities&service=wmts'],
        tileUrl: 'https://app-md-mapproxy-prod.azurewebsites.net/api/proxy/sverige_wmts?',
        params: {
            LAYERS: 'topowebb',
            TILED: true,
            FORMAT: 'image/png',
            PROJECTION: 'EPSG:3857'
        },
        options: {
            attribution: 'Lantmäteriet',
            zIndex: 9,
            visible: true,
        },
    },
    {
        group: LayerGroup.BG,
        id: 'norgeibilder',
        type: LayerType.WMS,
        access: AccessMethod.BAAT,
        urls: [
            'https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.nib_utm33_v2',
            'https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.nib_utm33_v2',
            'https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.nib_utm33_v2',
        ],
        params: {
            LAYERS: '0',
            TILED: true,
            FORMAT: 'image/png',
            VERSION: '1.1.1',
        },
        options: {
            attribution: 'Statens Kartverk',
            zIndex: 11,
            visible: false,
        },
    },
    {
        group: LayerGroup.BG,
        id: 'sverige_orto',
        type: LayerType.WMS,
        access: AccessMethod.MAPP,
        urls: ['https://app-md-mapproxy-prod.azurewebsites.net/api/proxy/sverige_orto'],
        params: {
            LAYERS: 'Ortofoto_0.5',
            TILED: true,
            FORMAT: 'image/jpeg',
            VERSION: '1.1.1',
        },
        options: {
            attribution: 'Lantmäteriet',
            zIndex: 10,
            visible: false,
        },
    },
    {
        id: 'ulv',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/forvaltningsomrader_rovdyr/wms'],
        params: {
            LAYERS: 'ulv_forvaltningsomrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'bjorn',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/forvaltningsomrader_rovdyr/wms'],
        params: {
            LAYERS: 'bjorn_forvaltningsomrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'jerv',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/forvaltningsomrader_rovdyr/wms'],
        params: {
            LAYERS: 'jerv_forvaltningsomrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'gaupe',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/forvaltningsomrader_rovdyr/wms'],
        params: {
            LAYERS: 'gaupe_forvaltningsomrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'reinbeite_omr',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/wms?Service=wms&'],
        params: {
            LAYERS: 'reinbeite_omrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 18,
            visible: false,
        },
    },
    {
        id: 'reinbeite_distr',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://kart.miljodirektoratet.no/geoserver/wms?Service=wms&'],
        params: {
            LAYERS: 'reinbeitedistrikt_omrade',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'Miljødirektoratet',
            zIndex: 17,
            visible: false,
        },
    },
    {
        id: 'beitelaggrenser',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://wms.nibio.no/cgi-bin/beite'],
        params: {
            LAYERS: 'Beitelaggrenser',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'NIBIO',
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'beitelagnamn',
        type: LayerType.WMS,
        access: AccessMethod.NONE,
        urls: ['https://wms.nibio.no/cgi-bin/beite'],
        params: {
            LAYERS: 'Beitelagnamn',
            FORMAT: 'image/png',
            TRANSPARENT: true,
        },
        options: {
            attribution: 'NIBIO',
            maxResolution: 170,
            zIndex: 15,
            visible: false,
        },
    },
    {
        id: 'samebygrenser',
        type: LayerType.VECTOR,
        access: AccessMethod.NONE,
        urls: ['/api/samebygrenser'],
        options: {
            zIndex: 16,
            visible: false,
        },
    },
];
